<template>
  <section class="page-not-found">
    <div class="page-wrap d-flex align-items-center flex-row">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <span class="display-1 d-block">404</span>
            <div class="lead mb-4">The page you are looking for was not found.</div>
            <router-link :to="{ name: 'Home' }" class="button">Back to Home</router-link>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style scoped>
  .page-wrap {
    min-height: 100vh;
  }
</style>
